<template>
	<div ref="three">
		<div v-show="percentage != 100" class="percen flex ai-center jc-center"><el-progress type="circle" :percentage="percentage"></el-progress></div>
		<canvas v-show="percentage == 100" class="canvasC" ref="canvasC"></canvas>
	</div>
</template>

<script>
import configApi from '@config/configApi';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OrbitControls, MapControls } from 'three/examples/jsm/controls/OrbitControls.js';
var scene, AmbientLight, canvas, renderer, camera, controls, requestTime;
const width = window.innerWidth * 0.6;
const height = window.innerHeight * 0.88;
var overall = [],
	clickArr = [];
var loader = new STLLoader();
var dataJson = []; //所有数据
export default {
	data() {
		return {
			cnt: 0,
			percentage: 0
		};
	},
	mounted() {
		// 初始化场景
		this.initScene();
	},
	methods: {
		// 初始化场景
		initScene() {
			scene = new THREE.Scene();
			// 加上三色标
			// const axesHelper = new THREE.AxesHelper(1);
			// scene.add(axesHelper);
			this.start();
		},
		start() {
			// 初始化相机
			this.initCamera();
			// 初始化灯光
			this.initLight();
			// 初始化渲染器
			this.initRenderer();
			// 初始化事件
			// this.clickEvents();
			// 初始化鼠标控件
			this.initControls();
		},
		// 初始化相机
		initCamera() {
			// 设置相机位置，/* 四个参数分别表示：多少度视角，相机的宽高比，最近距离，最远可视距离*/
			camera = new THREE.PerspectiveCamera(45, width / height, 0.005, 1500);
			camera.position.set(0, 0, 5); //设置相机位置//相机摆放在世界的位置
			camera.visible = true; //虽然无效,但是通过这个属性判断渲染那个相机
			camera.lookAt(scene.position); //让相机对着场景中央
		},
		//初始化渲染器
		initRenderer() {
			// 渲染器
			canvas = this.$refs.canvasC;
			renderer = new THREE.WebGLRenderer({
				canvas: canvas,
				antialias: true, //是否开启反锯齿，设置为true开启反锯齿。
				preserveDrawingBuffer: false,
				autoClear: true,
				alpha: true, //是否可以设置背景色透明。
				logarithmicDepthBuffer: true //模型的重叠部位便不停的闪烁起来。这便是Z-Fighting问题，为解决这个问题，我们可以采用该种方法
			});
			camera.lookAt(scene.position);
			renderer.setSize(width, height); //设置渲染区域尺寸
			renderer.setPixelRatio(window.devicePixelRatio); //兼容高清屏幕
		},
		//初始化灯光
		initLight() {
			// 灯光
			var light = new THREE.HemisphereLight(0xefefff, 0x111122, 1);
			scene.add(light);
		},
		// 鼠标控件
		initControls() {
			controls = new OrbitControls(camera, renderer.domElement);
			window.addEventListener('resize', this.onWindowResize, false);
		},
		//可视窗口重置
		onWindowResize() {
			//加入事件监听器,窗口自适应
			camera.aspect =width / height;
			if (renderer) {
				renderer.setSize(width, height);
			}
			camera.updateProjectionMatrix();
		},
		animate() {
			if (renderer != null) {
				renderer.render(scene, camera);
			}
			if (overall.length > 0) {
				overall.forEach(item => {
					if (item.rotateStatus == 1) {
						switch (item.rotateShaft) {
							case 'X':
								item.rotateX(item.rotateSpeed);
								break;
							case 'Y':
								item.rotateY(item.rotateSpeed);
								break;
							case 'Z':
								item.rotateZ(item.rotateSpeed);
								break;
						}
					}
				});
			}

			requestTime = requestAnimationFrame(this.animate);
		},
		getDataOrder(val) {
			dataJson = [];
			let param = {
				unitId: val.unitId,
				typeId: val.typeId
			};
			this.$get('/backend-api/app/model/get', param).then(data => {
				if (data.code === 1000) {
					//直接赋值所有数据，加载模型市要用
					dataJson = data.data;
					let arr = [];
					//循环一边，筛选出只能点击的数据
					dataJson.forEach(item => {
						if (item.button == 1) {
							arr.push(item);
						}
					});
					// getMeasList(dataJson)
					// showDataJson = arr;
					// 设置页面，加载模型
					this.setView();
				}
			});
		},

		setView() {
			// 设置颜色，加载模型
			dataJson.forEach((item, i) => {
				item.stlFile = configApi.photoURL + item.stlFile;
				item.showColor = false;
				item.color = this.setColor(item.color);
				this.loaderFile(item, i, dataJson.length).then(data => {});
			});
		},
		setColor(color) {
			return Number('0x' + color);
		},
		loaderFile(item, i, length) {
			let _this = this;
			return new Promise((resolve, reject) => {
				loader.load(item.stlFile, function(geometry) {
					var material = new THREE.MeshPhongMaterial({
						color: item.color,
						transparent: item.transparent == 1 ? true : false,
						opacity: item.opacity, // 定义物体的透明度。与 transparent 属性一起使用。该属性的赋值范围从 0 到 1。
						visible: true, //定义该材质是否可见。如果设置为 false,那么在场景中就看不到该物体。
						depthTest: true //遮挡
					});
					overall[i] = new THREE.Mesh(geometry, material);
					overall[i].position.set(item.pointX, item.pointY, item.pointZ);
					overall[i].rotation.set(item.rotaX, item.rotaY, item.rotaZ);
					overall[i].scale.set(item.scaleX, item.scaleY, item.scaleZ);
					overall[i].rotateStatus = item.rotateStatus;
					overall[i].rotateSpeed = item.rotateSpeed;
					overall[i].rotateShaft = item.rotateShaft;
					overall[i].measId = item.measId;
					overall[i].alarmColor = item.color;
					overall[i].name = item.name;
					overall[i].ids = item.id;
					let obj = overall[i];
					if (item.measId) {
						clickArr.push(obj);
					}
					scene.add(overall[i]);
					_this.cnt += 1; //加载成功数+1
					let sum = Math.round((_this.cnt / length) * 100);
					_this.percentage = sum;
					if (_this.cnt >= length) {
						//全部加载完
						// 执行渲染
						_this.animate();
						resolve({
							type: true,
							sum: sum
						});
						reject(false);
					} else {
						resolve({
							sum: sum
						});
					}
				});
			});
		},
		setType(id, i,color) {
			let arr = overall,
				len = overall.length;
			for (let a = 0; a < len; a++) {
				if (arr[a].ids == id) {
					arr[a].material.color.set('#'+color);
				}
			}
		},
		clearCache(item) {
			try {
				if (item.type === 'Mesh') {
					item.geometry.dispose();
					item.material.dispose();
					scene.remove(item);
				} else if (item.type === 'line') {
					item.geometry.dispose();
					item.material.dispose();
					scene.remove(item);
				} else {
					scene.remove(item);
				}
			} catch (es) {
				console.log('发生异常:' + es);
			}
		},

		clearScene() {
			this.removeObj(scene);
			dataJson = [];
			overall = [];
			renderer.dispose();
			scene = null;
			camera = null;
			controls = null;
			renderer = null;
			cancelAnimationFrame(requestTime);
		},

		removeObj(obj) {
			let arr = obj.children.filter(x => x);
			arr.forEach(item => {
				if (item.children.length) {
					this.removeObj(item);
				} else {
					this.clearCache(item);
					item.clear();
				}
			});
			obj.clear();
			arr = null;
		}
	}
};
</script>

<style>
.percen {
	z-index: 999;
	background: #000b17;
	width: 100%;
	height: 100%;
}
.canvasC {
	width: 100%;
	height: 100%;
}
</style>
