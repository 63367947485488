<template>
	<div class="flex" style="flex-direction: column;height: 100%;position:relative">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col :span="40">
						<el-form-item label="选择企业：">
							<el-select v-model="searchParm.entId" clearable :popper-append-to-body="false" placeholder="请选择">
								<el-option v-for="item in ents" :key="item.abbr" :label="item.abbr" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="40">
						<el-input v-model="searchParm.keyword" style="min-width: 300px;" placeholder="请输入关键字搜索">
							<i class="el-input__icon el-icon-search" slot="suffix"></i>
						</el-input>
					</el-col>
					<el-col :span="40">
						<el-row>
							<el-form-item label="报警时间：">
								<el-col :span="10">
									<el-date-picker v-model="searchParm.startTime" value-format="yyyy-MM-dd hh:mm:ss" type="datetime" placeholder="请选择日期"></el-date-picker>
								</el-col>
								<el-col :span="2" style="text-align: center;"><span>-</span></el-col>
								<el-col :span="10">
									<el-date-picker v-model="searchParm.endTime" value-format="yyyy-MM-dd hh:mm:ss" type="datetime" placeholder="请选择日期"></el-date-picker>
								</el-col>
							</el-form-item>
						</el-row>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>报警列表</span>
				</div>
				<div class="title-right">
					<el-button type="primary" size="medium" style="margin-right: 8px;" @click="download()">下载excel</el-button>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table
							ref="usertable"
							:data="tableData.records"
							border
							:height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }"
							:cell-style="{ padding: 0 }"
							style="margin-bottom: 20px;"
						>
							<template slot="empty">
								<el-empty :image-size="100" description="暂无数据"></el-empty>
							</template>
							<el-table-column width="80" align="center" label="序号" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>{{ scope.$index + 1 }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="entName" align="center" label="企业简称" show-overflow-tooltip></el-table-column>
							<el-table-column prop="unitName" align="center" label="设备名称" show-overflow-tooltip></el-table-column>
							<el-table-column prop="lineName" align="center" label="归属产线" show-overflow-tooltip></el-table-column>
							<el-table-column prop="userName" align="center" label="包机人" show-overflow-tooltip></el-table-column>
							<el-table-column prop="userTel" align="center" label="联系电话" show-overflow-tooltip></el-table-column>
							<el-table-column align="center" label="启停状态">
								<template slot-scope="scope">
									<div slot="reference" class="name-wrapper">{{ scope.row.haltStatus == 0 ? '开机' : '停机' }}</div>
								</template>
							</el-table-column>
							<el-table-column align="center" label="报警等级(最高)">
								<template slot-scope="scope">
									<div slot="reference" class="name-wrapper">{{ alarmLevel[scope.row.alarmLevel - 1] }}</div>
								</template>
							</el-table-column>
							<el-table-column align="center" label="数据来源">
								<template slot-scope="scope">
									<div slot="reference" class="name-wrapper">{{ types[scope.row.source] }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="alarmTime" align="center" label="报警时间" show-overflow-tooltip></el-table-column>
							<el-table-column align="center" label="状态">
								<template slot-scope="scope">
									<div slot="reference" class="name-wrapper">{{ status[scope.row.status] }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="pushTime" align="center" label="推送时间" show-overflow-tooltip></el-table-column>
							<el-table-column prop="clearUser" align="center" label="解除人" show-overflow-tooltip></el-table-column>
							<el-table-column prop="clearTime" align="center" label="解除时间" show-overflow-tooltip></el-table-column>
							<el-table-column prop="remark" align="center" label="备注" show-overflow-tooltip></el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="230">
								<template slot-scope="scope">
									<div>
										<el-button type="text" :disabled="scope.row.status != 1" @click="openView(scope.row)">推送报警</el-button>
										<!-- <el-button type="text" :disabled="scope.row.status != 1" @click="push(scope.row)">推送报警</el-button> -->
										<el-button type="text" :disabled="scope.row.status != 2" @click="relieve(scope.row)">解除报警</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination
						style="float: right;"
						@current-change="handleCurrentChange"
						:current-page.sync="searchParm.current"
						:page-size="searchParm.size"
						layout="total, prev, pager, next,jumper"
						:total="tableData.total"
					></el-pagination>
				</div>
			</div>
		</div>
		<div class="tipBox" v-if="showBox">
			<div class="tipBoxTop">
				<span>推送报警</span>
				<i class="el-icon-close" @click="closesss()"></i>
			</div>
			<div class="tipBoxContent">
				<div class="tipBoxContentLeft">
					<el-menu v-if="menuList.length > 0" default-active="2" class="el-menu-vertical-demo" :default-openeds="openedsArr">
						<el-submenu v-for="(item, index) in menuList" :key="index" :index="item.ids">
							<template slot="title">
								<span style="color:#333333">{{ item.group }}</span>
							</template>
							<el-menu-item active-text-color="#333333" v-for="(its, i) in item.alarmComps" :key="i" :index="its.id">
								<template slot="title">
									<div class="flex ai-center jc-between">
										<el-tooltip effect="light" :content="its.name" placement="top-start">
											<div class="titleBox2">{{ its.name }}</div>
										</el-tooltip>
										<el-radio-group v-model="its.alarmLevel" size="mini" @change="radioChange($event, index, i)">
											<el-radio-button label="0">正常</el-radio-button>
											<el-radio-button label="1">一级报警</el-radio-button>
											<el-radio-button label="2">二级报警</el-radio-button>
											<el-radio-button label="3">三级报警</el-radio-button>
										</el-radio-group>
									</div>
								</template>
							</el-menu-item>
						</el-submenu>
					</el-menu>

					<el-empty v-else :image-size="100" description="暂无数据"></el-empty>
				</div>
				<div class="tipBoxContentRight"><threes ref="threes" style="width:100%;height:100%"></threes></div>
			</div>
			<div class="tipBoxButtom">
				<div>
					<el-button @click="closesss()">取消</el-button>
					<el-button type="primary" @click="push()">确定</el-button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import caches from '@cache/cache.js';
import smCard from '@c/smCard.vue';
import threes from './three';
export default {
	components: {
		smCard,
		threes
	},
	data() {
		return {
			tableHeight: 0,
			tableData: {
				records: []
			},
			searchParm: {
				size: 20,
				current: 1
			},
			ents: [],
			types: ['振动', '转速', '温度', '油液', 'wincc'],
			alarmLevel: ['一级报警', '二级报警', '三级报警'],
			showBox: false,
			menuList: [],
			openedsArr: [],
			status: {
				1: '未推送',
				2: '已推送',
				3: '已解除',
				21: '自动推送',
				31: '自动解除'
			},
			param: {
				alarmComps: []
			}
		};
	},
	methods: {
		//自适应表格高度
		calHeight() {
			this.$nextTick(() => {
				const rect = this.$refs.tableContainer.getBoundingClientRect();
				this.tableHeight = rect.height;
			});
		},
		//获取企业列表
		getEnts() {
			this.$get('/backend-api/sys/ent/list', {
				size: 99
			}).then(res => {
				if (res.code != 1000) {
					this.$message.error(res.msg);
					return;
				}
				this.ents = res.data.records;
			});
		},
		//清空搜索
		clearSearch() {
			this.searchParm = {
				size: 20,
				current: 1
			};
		},
		//展示数据
		list() {
			this.$get('/backend-api/alarm/unit/list', this.searchParm).then(res => {
				if (res.code != 1000) {
					this.$message.error(res.msg);
					return;
				}
				this.tableData = res.data;
			});
		},
		//推送
		push() {
			this.param.alarmComps = [];
			this.menuList.forEach(item => {
				item.alarmComps.forEach(item2 => {
					this.param.alarmComps.push({
						id: item2.id,
						alarmLevel: item2.alarmLevel
					});
				});
			});
			this.$confirm('此操作将推送报警, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$postJson('/backend-api/alarm/unit/push', this.param).then(res => {
					this.closesss()
					if (res.code != 1000) {
						this.$message.error(res.msg);
						return;
					}
					this.$message({
						message: '操作成功',
						type: 'success'
					});
					this.list();
				});
			});
		},
		relieve(row) {
			this.$confirm('此操作将解除报警, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$post('backend-api/alarm/unit/clear?id=' + row.id).then(res => {
						if (res.code != 1000) {
							this.$message.error(res.msg);
							return;
						}
						this.$message({
							message: '操作成功',
							type: 'success'
						});
						this.list();
					});
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
		},

		//分页选择页面回调
		handleCurrentChange(val) {
			this.searchParm.current = val;
			this.list();
		},
		openView(val) {
			this.showBox = true;
			this.param.id = val.id;
			this.$get('/backend-api/alarm/unit/getComp?id=' + val.id).then(res => {
				if (res.code != 1000) {
					this.$message.error(res.msg);
					return;
				}
				let arr = res.data;
				this.openedsArr = [];
				arr.forEach((item, i) => {
					item.ids = 'ids_' + i;
					this.openedsArr.push(item.ids);
					item.alarmComps.forEach(item2 => {
						let color;
						switch (item2.alarmLevel) {
							case 0:
								color = item2.alarm0Color;
								break;
							case 1:
								color = item2.alarm1Color;
								break;
							case 2:
								color = item2.alarm2Color;
								break;
							case 3:
								color = item2.alarm3Color;
								break;
							default:
								break;
						}
						setTimeout(() => {
							this.$refs.threes.setType(item2.id, item2.alarmLevel, color);
						}, 100);
					});
				});
				this.menuList = arr;
			});
			//加载模型
			setTimeout(() => {
				this.$refs.threes.getDataOrder(val);
			}, 100);
		},
		radioChange(e, index, i) {
			let color,
				item2 = this.menuList[index].alarmComps[i];
			switch (item2.alarmLevel) {
				case '0':
					color = item2.alarm0Color;
					break;
				case '1':
					color = item2.alarm1Color;
					break;
				case '2':
					color = item2.alarm2Color;
					break;
				case '3':
					color = item2.alarm3Color;
					break;
				default:
					break;
			}
			setTimeout(() => {
				this.$refs.threes.setType(item2.id, item2.alarmLevel, color);
			}, 100);
		},
		download() {
			let url = this.baseURL + '/backend-api/alarm/unit/download?ent_id=null';
			if(this.searchParm.endTime){
				url+='&endTime='+this.searchParm.endTime
			}
			if(this.searchParm.keyword){
				url+='&keyword='+this.searchParm.keyword
			}
			if(this.searchParm.startTime){
				url+='&startTime='+this.searchParm.startTime
			}
			if(this.searchParm.status){
				url+='&status='+this.searchParm.status
			}
			// //访问路径直接下载
			window.location.href = url;
		},
		closesss() {
			this.showBox = false;
			this.$refs.threes.clearScene();
		}
	},
	mounted() {
		this.list();
		this.getEnts();
		this.calHeight();
		window.addEventListener('resize', this.calHeight);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calHeight);
		this.showlookListDialog = false;
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
.tipBox {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #ffffff;
	z-index: 999;
	.tipBoxTop {
		width: 100%;
		padding: 10px 20px;
		background: #ffffff;
		font-size: 18px;
		height: 44px;
		> i {
			float: right;
			font-size: 28px;
			cursor: pointer;
		}
	}
	.tipBoxContent {
		// width: 100%;
		height: calc(100% - 100px);
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		.tipBoxContentLeft {
			flex: 4;
			height: 100%;
			min-width: 700px;
			overflow: auto;
			.titleBox2 {
				color: #333333;
				padding-left: 10px;
				width: 40%;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap; // 默认不换行
			}
		}
		.tipBoxContentRight {
			flex: 6;
			height: 100%;
			background: #000b17;
		}
	}
	.tipBoxButtom {
		width: 100%;
		background: #ffffff;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 40px;
		border-top: 1px solid #555555;
	}
}
</style>
